<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full p-10"
        >
          <div class="text-center">
            <span
              class="text-red-alert text-c24 font-c7"
            >
              Attention
            </span>
          </div>

          <div class="text-center text-c18 font-c4 mt-6">
            <div
              class=""
            >
              Vous êtes sur le point de supprimer ce {{ type }}, souhaitez-vous continuer ?
            </div>
          </div>

          <div class="mt-6 bloc p-4">
            <div class="flex items-center">
              <nameLabel
                  :first="donnes.fullname"
                  :second="donnes.fullname.substr(1,1)"
                  height="32px"
                  width="32px"
                  size="12px"
              />
              <div class="w-5/6 ml-4 text-c16">
                <div class="text-left">
                  {{ donnes.fullname }}
                </div>
              </div>

            </div>
          </div>

          <div class="mt-10 flex">
            <div class="w-1/2">
              <buton
                background="#EAEAEF"
                color="#606060"
                label="ANNULER"
                @oga="fermer"
              />
            </div>

            <div class="w-1/2 ml-6">
              <buton
                  background="#F82626"
                label="SUPPRIMER"
                  :charge="charge"
                @oga="continuer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import nameLabel from '../helper/add/nameLabel'
import apiroutes from "../../router/api-routes"
import http from "../../plugins/https"

export default {
  name: 'Success',
  components: { PopupBase, buton, nameLabel },
  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donnes: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        ic_agence,
      },
      charge: false
    }
  },
  created() {
    this.wantToMakeOffer = this.activation
  },
  methods: {
    fermer () {
      this.$emit('oga', false)
    },
    continuer () {
      this.charge = true
      http.delete(apiroutes.baseURL + apiroutes.deleteCommercial + '?id=' + this.donnes.id)
          .then(response => {
            console.log(response)
            this.charge = false
            this.$emit('oga', true)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },
    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 33%;
    }

    .bloc{
      background: #EFF3FE;
      border: 0.5px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 8px;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
