<template>
  <div
    class="inputo p-1 flex items-center pl-4"
    :class="{'error': error !== null,
             'success': success === true}"
    :style="{'height': height, 'border': border, 'border-radius': radius, 'background-color': background}"
  >
    +229
    <input
      v-model="value"
      type="text"
      class="w-full h-full pl-3 pr-3"
      :placeholder="placeholder"
      maxlength="11"
      @keypress="isNumberOnly"
    >
    {{ sendValue }}
  </div>
</template>

<script>
export default {
  name: 'Index',
  components: {
  },

  props: {
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    valueT: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '40px'
    },
    border: {
      type: String,
      default: '1px solid #DCDCE4'
    },
    radius: {
      type: String,
      default: '4px'
    },
    background: {
      type: String,
      default: 'white'
    }
  },

  data () {
    return {
      error: null,
      success: false,
      value: ''
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.value.length === 8) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.success = true
        const phone = this.value.substr(0, 2) + ' ' + this.value.substr(2, 2) + ' ' + this.value.substr(4, 2) + ' ' + this.value.substr(6, 2)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.value = phone
        this.$emit('info', '229' + this.value.replace(/ /g, ''))
      }
      if (this.value.length === 10) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.error = 'email'
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.value = this.value.replace(/ /g, '')
      }
      if (this.value < 8) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.error = 'email'
      }
    }
  },

  created () {
    this.value = this.valueT
  },

  methods: {
    isNumberOnly ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    },

    emailValidation (email) {
      const re = /\S+@\S+\.\S+/
      return re.test(email)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables";
.inputo{
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 15.2377px;
  height: 70.09px;
  font-family: $font-default;
  font-size: 14px;
  line-height: 34px;
  color: #0e0e0e;
}
input{
  font-weight: 500;
  font-size: 14px;
  line-height: 34px;
  color: #0e0e0e;
}
.inputo:focus-within {
  border: 1px solid $focus-color;
}
.inputo:hover {
  border: 1px solid $hover-color;
}
.error{
  border: 1px solid #ea9f9f!important;
}
.success{
  border: 1px solid #5fc187!important;
}
.lab{
  background: #F2F2F2;
  border-radius: 10px;
  height: 35px;
  font-weight: bold;
  font-size: 14px;
  line-height: 27px;
  color: #979797;
}
</style>
